
.icon-with-circle {
  border: 0.15rem solid white;
  border-radius: 2rem;
  padding: 0.15rem;
  height: 2.5rem;
  width: 2.5rem;
  background-color: #c4e5db;
  transform: rotate(-270deg);
}
.icon-without-circle {
  border: none;
  border-radius: 2rem;
  height: 2.5rem;
  padding: 0.15rem;
  width: 2.5rem;
  background-color: #c4e5db;
  transform: rotate(-270deg);
}