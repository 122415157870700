.background__container_customer_order {
  background-image: url("../images/customo-bg.webp");
}

.custom-order-title {
  color: white;
  padding-top: 2rem;
  text-align: center;
}

.custom-order-feature-tile {
  margin-top: 8rem;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 90%;
  text-align: center;
  color: white;
}

@media screen and (min-width: 1286px) {
  .custom-order-feature-tile {
    padding-bottom: 2em;
  }
}

@media screen and (max-width: 1207px) {
  .custom-order-feature-tile {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }

  .custom-order-feature-tile :nth-child(1) {
    order: 2;
  }

  .custom-order-feature-tile :nth-child(2) {
    order: 1;
  }

  .custom-order-feature-tile :nth-child(3) {
    order: 3;
  }
}

.outer-div {
  display: flex;
  justify-content: center;
}

.bottom-text {
  margin-top: 7rem;
}

.top-text {
  margin-top: 2.8rem;
}

.custom-form-background {
  height: 100%;
  background-color: #87ddda;
  padding: 2rem;
}

.custom-order-form-border {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5490196078431373);
  border-radius: 2rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.custom-order-form-heading {
  color: white;
  font-size: 1.6rem;
  font-weight: 500;
}

.custom-order-form-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 1rem;
}

.add-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
  border: none;
  border-radius: 0.8rem;
  padding: 1rem;
  width: 20rem;
}

select{
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
  background-position-x: 280px;
}

.add-input-textfield {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
  border: none;
  border-radius: 0.8rem;
  padding: 1rem;
  width: 42rem;
  margin-left: 1rem;
}

@media screen and (max-width: 770px) {
  .custom-order-form-row {
    flex-direction: column;
    margin: 0;
  }

  .add-input-textfield {
    width: 20rem;
    margin-left: 0;
  }

  select{
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
    background-position-x: 190px;
  }

  .add-input{
    align-items: center;
  }
}

@media screen and (max-width: 448px) {
  .add-input {
    width: 14rem;
  }

  .add-input-textfield {
    width: 14rem;
  }
}

.spacer {
  margin-right: 2rem;
}

.submit-button {
  color: white;
  background-color: #ff8d95;
  border: none;
  padding: 0.3rem 2rem;
  border-radius: 0.9rem;
  height: 2.5rem;
  margin-bottom: 1rem;
}

.custom-form-submit {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}

@media screen and (max-width: 1286px) {
  .customorder_main_container_image {
    height: 23rem;
    width: 23rem;
  }
}
input[type="file"] {
  width: 100px;
  border: 2px solid #ff8d95;
  background-color: #ff8d95;
  color: #ff8d95;
}
