#scroll-container {
  overflow: hidden;
  border: 2px solid white;
  padding: 0.6em 0;
  color: white;
  overflow: hidden;
  background-color: var(--lightgreen);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

#scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 25s linear infinite;
  -webkit-animation: my-animation 25s linear infinite;
  animation: my-animation 25s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
