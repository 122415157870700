.order_page__container {
  background-image: url("../images/Check.webp");
  min-height: 50vh;
  padding-bottom: 1rem;
}

.order-heading {
  text-align: center;
  color: white;
}

.order-history {
  background-color: var(--backgroundgrey);
  border-radius: 1rem;
  margin: 0.75rem 5rem;
  padding: 1.5rem;
}

.order-main {
  display: flex;
  flex-direction: column;
}

.order-main:hover {
  cursor: pointer;
}

.order-detail {
  display: flex;
  flex-direction: row;
}

.order-id {
  text-align: center;
  padding-bottom: 1rem;
  font-size: 1.2rem;
  color: #f7fbfb;
}

.order-name,
.order-weight,
.order-price,
.order-quan {
  color: white;
  font-weight: 600;
}

.order-disc {
  margin-left: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.order-disc h2 {
  color: #f7fbfb;
}

.order-img {
  width: 10rem;
  height: 10rem;
  border-radius: 0 2rem 0 2rem;
  padding-bottom: 1rem;
  -webkit-border-radius: 0 2rem 0 2rem;
  -moz-border-radius: 0 2rem 0 2rem;
  -ms-border-radius: 0 2rem 0 2rem;
  -o-border-radius: 0 2rem 0 2rem;
}

@media screen and (min-width: 766px) {
  .order__timeline_container {
    max-width: 90%;
  }
}

.order__main_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  padding: 0 0.5em 0 0.5em;
}

.timeline_logo {
  object-fit: cover;
}

@media screen and (max-width: 766px) {
  .order-detail,
  .order__main_container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .order-history {
    margin: 1rem 1rem;
  }

  .order__timeline_container {
    margin: 0.75rem 0;
  }
}
