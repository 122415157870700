.profile__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.profile__heading {
    color: var(--brown);
}

.profile__top_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile__bottom_container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@media screen and (max-width: 784px) {
    .profile__top_container{
        flex-direction: column;
    }
    .profile__bottom_container {
        flex-direction: column;
        justify-content: center;
    }
}

/* profile css */

.profile__profile_container {
    width: 90%;
    border: 1px solid var(--brown);
    display: flex;
    align-items: flex-start;
    justify-self: start;
    padding: 1em;
    border-radius: 0 1rem 0 1rem;
    -webkit-border-radius: 0 1rem 0 1rem;
    -moz-border-radius: 0 1rem 0 1rem;
    -ms-border-radius: 0 1rem 0 1rem;
    -o-border-radius: 0 1rem 0 1rem;
}

@media screen and (max-width: 784px){
    .profile__profile_container {
        width: 80vw;
        flex-direction: column;
        align-items: center;
        justify-self: center;
    }

    .profile_user_detail_container{
        flex-direction: column;
    }
}

.profile_user_detail_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
}

.profile_user_detail_container>img {
    clip-path: circle();
    height: 8rem;
    width: 8rem;
}

.profile_user_name {
    color: var(--brown);
    padding: 1em;
    font-size: x-large;
}

.profile_user_detail_edit_container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    height: 10rem;
}

@media screen and (max-width: 784px){
    .profile_user_name{
        padding: auto;
        font-size: large;
    }
    .profile_user_detail_edit_container{
        align-items: center;
        justify-content: center;
        height: auto;
            
    }
}

.profile_user_detail_edit_container__member_since {
    font-size: small;
    color: var(--brown);
}

.edit_profile_button {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--lightpink);
    color: white;
    border: 0;
    padding: 0.2em;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
}


/* left wallet container */

.profile__left_balance_container {
    width: 30%;
    border: 1px solid var(--brown);
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    margin-left: 3rem;
    padding: 1em;
    border-radius: 0 1rem 0 1rem;
    -webkit-border-radius: 0 1rem 0 1rem;
    -moz-border-radius: 0 1rem 0 1rem;
    -ms-border-radius: 0 1rem 0 1rem;
    -o-border-radius: 0 1rem 0 1rem;
}


@media screen and (max-width: 784px) {
    .profile__left_balance_container {
        width: 80vw;
        margin-left: 0;
        margin-top: 1em;
    }
}   

/* small containers */

.profile__small_container {
    width: auto;
    flex-grow: 1;
    transition: transform 200ms ease-in-out;
    border: 1px solid var(--brown);
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    padding: 1em;
    margin: 1em;
    border-radius: 0 1rem 0 1rem;
    -webkit-border-radius: 0 1rem 0 1rem;
    -moz-border-radius: 0 1rem 0 1rem;
    -ms-border-radius: 0 1rem 0 1rem;
    -o-border-radius: 0 1rem 0 1rem;
    -webkit-transition: transform 200ms ease-in-out;
    -moz-transition: transform 200ms ease-in-out;
    -ms-transition: transform 200ms ease-in-out;
    -o-transition: transform 200ms ease-in-out;
}

.profile__small_container:hover{
    cursor: pointer;
    border:  var(--lightpink);
    color: white;
    background-color: var(--lightpink);
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

@media screen and (max-width: 784px) {
    .profile__small_container{
        width: 80vw;
    }
}

.profile__small_container>img {
    height: 3rem;
    width: 3rem;
}

/* logout */

.profile__logout__button {
    transition: transform 200ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--lightpink);
    color: white;
    border: 0;
    padding: 0.2em 1em;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    -webkit-transition: transform 200ms ease-in-out;
    -moz-transition: transform 200ms ease-in-out;
    -ms-transition: transform 200ms ease-in-out;
    -o-transition: transform 200ms ease-in-out;
}

.profile__logout__button:hover{
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}