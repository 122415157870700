.end-images {
  height: 3rem;
  width: 3rem;
  margin: 0 1rem;
  margin-top: 0.5rem;
  vertical-align: middle;
}

.cart_list{
  position: absolute;
  right: 0;
  top: 0;
  background-color: red;
  border-radius: 50%;
padding: 0.3em 0.7em;
color: white;
}
.app-logo {
  height: 4rem;
  width: 8rem;
}

.nav-search-icon{
  margin-top: 1rem;
}

@media screen and(max-width:900px) {
  .nav-search-icon{
    margin: 0;
  }
}

.navbar__link {
  text-decoration: none;
  color: white;
}

.navbar__link:hover {
  color: white;
  text-decoration: underline;
}

.navbar {
  z-index: 2;
}