body {
  font-family: "Open Sans Condensed";
  padding: 0px;
  margin: 0px;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}

.home__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("./images/cake.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 2rem;
  width: 500px;
  height: 500px;
  background-color: white;
  border: 2px solid #000;
  border-radius: 10px;

  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.home__modal__title {
  text-align: center;
  color: white;
}

.large_modal_home {
  font-size: 4.5rem;
  margin-bottom: 1rem;
  color: var(--lightpink);
}

.overlay_home_modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.close__button__home {
  position: absolute;
  top: 5px;
  right: 5px;

  border-radius: 50%;
  display: flex;

  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  background: none;
  background-color: white;

  box-shadow: none;
  cursor: pointer;

  border: none;
  outline: none;
}

@media screen and (max-width: 768px) {
  .home__modal{
    width: 90%;
    height: 70%;
  }

  .large_modal_home{
    font-size: 3rem;
    margin-bottom: 1rem;
    color:var(--lightpink)
  }
}

