
.verify-page {
  background-image: url("../images/Rectangle44.webp");
  padding: 1em 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verify-heading {
  color: #602912;
  font-size: 3rem;
  text-align: center;
}

.verify-main {
  padding: 1em 3em;
  border-radius: 2rem;
  background-color: rgb(247, 225, 217);
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
}

.verify-mid-sec div {
  margin: 1rem;
}
.verify-mid-sec {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width:766px) {
  .verify-mid-sec{
    flex-direction: column;
  }
}

.verify-cake {
  height: 15rem;
  width: 15rem;
  margin-right: 2rem;
}
.sealimg {
  height: 8rem;
  width: 11rem;
}
.cakeimg {
  height: 15rem;
  width: 15rem;
  margin-right: 2rem;
}

.verify-mid-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.verify-mid-right input {
  margin: 10px;
  border: none;
  padding: 5px;
  border-radius: 4px;
}
.verify-button {
  color: white;
  background-color: #ff8d95;
  border: none;
  padding: 0.3rem 2rem;
  border-radius: 0.9rem;
}
.enter-otp {
  color: #602912;
  font-size: 1.5rem;
}
.middle-right-div{
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.vl{
  border: 0.1rem solid #602912;
  height: 16rem;
}