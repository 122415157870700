.product-page {
  background-image: url("../images/Check.webp");
  padding-bottom: 2rem;
}
.product-main {
  display: flex;
  flex-direction: row;
}
.product-heading {
  text-align: center;
  color: #f7fbfb;
}
.product-preview-img {
  width: 4.2rem;
  height: 4.2rem;
}
.product-img {
  width: 20rem;
  height: 20rem;
}

.product-button {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
}
.product {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
}
.product-preview {
  display: flex;
  flex-direction: column;
}
.product-main h1 {
  color: black;
}
.product-main {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 2rem;
}
.product-disc {
  margin-left: 2rem;
}
.product-button button {
  background-color: #ff8d95;
  color: white;
  width: 3.5rem;
  height: 3rem;
  border: none;
  border-radius: 1rem;
}
.product-button {
  margin-top: 1rem;
}
.product-button h2 {
  margin: 0px 1.5rem 0px 1.5rem;
  color: black;
}
.product-buy-btn {
  border: none;
  border-radius: 1rem;
  background-color: #ff8d95;
  margin-left: 2rem;
  margin-top: 1rem;
  height: 3rem;
  width: 11rem;
  color: #f7fbfb;
}
.product-price {
  margin-top: 1rem;
  color: black;
}
.product-para {
  color: black;
  font-size: 1.4rem;
}
.product-cat-weight {
  
  
  width: 178px;
  padding: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 5px;
  height: 34px;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  -webkit-appearance: none;
  background-position-x: 150px;
  align-items: center;

}
.preview-item {
  margin: 0.5rem;
}
.product-desc-heading {
  font-weight: lighter;
}

@media screen and (max-width: 900px) {
  .product-main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .product-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .product {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 0;
  }
  .product-img {
    padding: 2rem;
  }
  .product-disc {
    margin-left: 0;
  }
  .product-button{
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-buy-btn{
    margin-left: 0;
  }

  .product-buy-button-div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
}
