.about_us_container{
  background-image: url("../images/Check.webp");
  color: white;
}

.inside-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--backgroundgrey);
  width: 85%;
  padding: 1rem;
  border-radius: 0.8rem;
}

.about-page {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inside-wrapper p {
  font-size: 1.3rem;
  color: white;
  text-align: justify;
  margin-right: 2.5rem;
}

.about-inside-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
}

.about_container_image{
  height: 15rem;
  width: 15rem;
  object-fit: fill;
  border-radius: 0.75rem;
}

@media screen and (max-width: 802px) {
  .about-inside-wrapper {
    flex-direction: column-reverse;
  }
  .about-para-1,
  .inside-wrapper p{
    margin-right: 0;
    margin-left: 0;
  }

}

@media screen and (max-width: 540px) {
  .about_container_image {
    max-width: 90%;
    margin-bottom: 0.8rem;
  }
}

.about-inside-wrapper-2 {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 802px) {
  .about-inside-wrapper-2 {
    flex-direction: column;
  }
}


.about-para-2 {
  margin-left: 2.5rem;
}