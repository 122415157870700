.checkout-page {
  background-image: url("../images/Check.webp");
  padding-top: 2rem;
}
.checkout-first {
  margin-right: 1em;
  padding: 1.2rem;
  min-width: 20%;
  /* width: 60%; */
  /* display: flex; */
  flex-direction: column;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5490196078431373);
  border-radius: 1rem;
}
.checkout-product {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (max-width: 967px) {
  .checkout-first {
    margin: 1em;
  }
  .checkout-product {
    flex-direction: column;
  }
}
.checkout-product-img {
  width: 13rem;
  height: 13rem;
}
.checout-product-desc h2 {
  color: white;
}
.checkout-product-details {
  display: flex;
  flex-direction: row;
}
.checkout-add {
  background-color: rgba(0, 0, 0, 0.5490196078431373);
  border-radius: 1rem;
  padding: 0.5rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.cart-image-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-image-subcontainer2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-detail-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50vw;
}

.checkout-quantity {
  margin-left: 7rem;
}
.checkout-price {
  margin-left: 5rem;
}
.checkout-price h2 {
  color: white;
}
.checkout-price h4 {
  color: white;
  margin-top: 5.5rem;
}

.checkout-quantity h2 {
  color: white;
  margin: 0 1rem 0 1rem;
}
.checkout-quan-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 5.5rem;
}

.checkout-quan-btn button {
  background-color: #ff8d95;
  color: white;
  width: 2.4rem;
  height: 2.4rem;
  border: none;
  border-radius: 0.8rem;
}
.checkout-product-desc {
  margin-top: 3.5rem;
}
.checkout-item {
  margin-left: 4.5rem;
  color: white;
}
.checkout-product-name {
  color: white;
}
.checkout-product-weight {
  color: white;
  margin-top: 2rem;
}
.checkout-product-stock {
  color: white;
  margin-top: 2rem;
}
.checkout-coupon {
  margin-top: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.8rem;
}

.checkout-message {
  margin-top: 1rem;
  padding: 0.5rem;
  border: none;
  width: 14rem;
  border-radius: 0.8rem;
}
.checkout-coupon-btn {
  background-color: #ff8d95;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 0.4rem;
  margin-left: 0.3rem;
}
.checkout-charges {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.checkout-final {
  margin-top: 1rem;
  border-bottom: 3px solid white;
}
.checkout-charges p {
  color: white;
}

.cart-product-image {
  height: 8rem;
  width: 8rem;
  border-radius: 0.4rem;
  margin-bottom: 0.7rem;
  margin-right: 1rem;
}

.cart-image-container {
  display: flex;
  flex-direction: row;
}

.cart-product-name {
  height: 2rem;
  vertical-align: middle;
  text-align: start;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  margin-right: auto;
}

.cart-button {
  background-color: #ff8d95;
  font-size: 1.2rem;
  color: white;
  border: none;
  border-radius: 0.5rem;
  width: 2.4rem;
  height: 2.4rem;
  text-align: center;
  margin: 0 1.5rem;
}

.cart-product-amount {
  height: 3rem;
  width: 5rem;
  vertical-align: middle;
  text-align: start;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}

.cart-radio-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
}

.checkout-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.checkout-button-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.checkout-and-continue-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-button {
  background-color: var(--lightpink);
  color: white;
  height: 3.5rem;
  border: none;
  width: 8rem;
  border-radius: 0.7rem;
  font-size: medium;
  margin: 0 0.3em;
  -webkit-border-radius: 0.7rem;
  -moz-border-radius: 0.7rem;
  -ms-border-radius: 0.7rem;
  -o-border-radius: 0.7rem;
}

.continue-button {
  color: var(--lightpink);
  background-color: white;
  border: 1px solid var(--lightpink);
}

.cart-radio-button-text {
  color: black;
}

.empty-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: larger;
}

.button-container {
  display: flex;
  align-items: space-evenly;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: medium;
}

@media screen and (max-width: 1270px) {
  .cart-detail-card {
    width: 40vw;
  }
}
@media screen and (max-width: 1038px) {
  .cart-detail-card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: auto;
  }
}

@media screen and (max-width: 967px) {
  .checkout-add {
    margin: 1em;
  }

  .cart-image-subcontainer2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cart-image-subcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .cart-image-subcontainer2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem;
    align-items: center;
  }

  .cart-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
