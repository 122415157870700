.locate-us-page {
  background-image: url("../images/Check.webp");
  min-height: 50vh;
  padding-bottom: 1rem;
}

.locate-us-container {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
}

.location-container {
  border-radius: 0.7rem;
  width: 100%;
  padding: 1rem;
}

.location-text {
  margin-top: 0.5rem;
  text-align: center;
}
@media screen and (max-width: 900px) {
    .locate-us-container {
      grid-template-columns: auto auto;
    }
  }

@media screen and (max-width: 540px) {
  .locate-us-container {
    grid-template-columns: auto;
  }
}

