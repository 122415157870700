.background__container_menu{
  background-image: url('../images/Check.webp');
  min-height: 20vh;
}

.category-header {
  padding-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.category-heading {
  color: #f7fbfb;
  font-size: 3rem;
}
.category-para {
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: justify;
  font-size: 1.2rem;
  color: #f7fbfb;
}

/* grid */

.category__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.category__container_gridcontainer {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.5em;
}

.category__container_gridbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.category__container_grid_content_container {
  padding: 1em 1em 1em 1em;
}

.category__container_grid_content_container_image {
  height: 18rem;
  width: 18rem;
  border: 0.4rem solid var(--lightyellow);
}

.category__container_grid_content_container_title {
  color: white;
  text-align: center;
  width: 18rem;
  padding: 0.2rem 0;
  margin-top: 1rem;
  font-size: 1.4rem;
  background-color: var(--lightyellow);
  border-radius: 0.75rem;
}
