@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap");
:root {
  --blue: #1e90ff;
  --backgroundgrey: #0000008c;
  --lightpink: #f39f9d;
  --brown: #602912;
  --lightgreen: #87ddda;
  --lightyellow: #ffd072;
}



.html {
  overflow-x: hidden;
}

select{
  -webkit-appearance : none;
}

.body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}

.background__container {
  display: grid;
  height: fit-content;
}

.background__container_image {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  z-index: -100;
}

.background__container_content {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
}

.loading__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  text-decoration: none;
}

.title_background_container{
  background-color: var(--lightpink);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:20px;
	right:20px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
