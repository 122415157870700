.banner_carousel {
  width: 100%;
  top: 0;
}

.banner_carousel_item {
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 100%;
  object-fit: fill;
}

.banner_carousel_item_text {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-left: calc(1em + 1vw);
}

@media screen and (max-width: 802px) {
  .banner_carousel_item {
    height: 50vh;
  }
}

@media screen and (max-width: 540px) {
  .banner_carousel_item {
    height: 30vh;
  }
}

