.footer-heading {
  text-align: center;
  color: #602914;
  font-size: 2rem;
  font-weight: 500;
}

.footer-category-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1.3rem;

  cursor: pointer;
  font-size: 1.4rem;
}

@media screen and (max-width: 900px) {
  .footer-category-link {
    margin: 1em auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: max-content;
    
  }
}

.items {
  display: flex;
  flex-direction: row;
}

.footer-items {
  color: #602914;
  font-size: 1.4rem;
  margin: 0 0.4rem;
}

.footer-dots {
  color: #602914;
  margin: 0 0.4rem;
  font-size: 3rem;
}

.footer-page {
  width: 100%;
  padding: 1rem 0;
}

.location-heading {
  color: #602914;
  font-size: 1.5rem;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  text-align: center;
}

.diff-locations {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-vl {
  border: 0.05rem solid;
  height: 6rem;
  margin: 0 2rem;
}

.location-address {
  width: 22%;
}

@media screen and (max-width: 900px) {
  .diff-locations {
    flex-direction: column;
  }

  .footer-vl {
    height: 0.1rem;
    width: 70%;
    background-color: var(--brown);
    margin: 0.2em 0;
  }

  .location-address {
    width: 90%;
    text-align: center;
  }
}

.address-text {
  font-size: 1.2rem;
  color: #602914;
}

.footer-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.footicon {
  height: 2rem;
  width: 2rem;
  margin: 0 1rem;
  object-fit: fill;
}
.online-order-text {
  color: #602914;
  text-align: center;
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

a{
  color: #602914;
  text-decoration: none;
}

a:hover{
  color: #602914;
}

@media screen and (max-width: 778px) {
  .diff-locations,
  .location-heading {
    display: none;
  }

  .footer-items{
    font-size: 1rem;
  }
}
