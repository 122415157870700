.selected-address,
.unselected-address {
  padding: 1rem;
  cursor: pointer;
}

.selected_address_container {
  /* margin-top: 1rem; */
  /* width: 100%; */
  background-color: var(--brown);
  color: white;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  margin:1rem 3rem;
  padding: 1em;
  border-radius: 0 1rem 0 1rem;
  -webkit-border-radius: 0 1rem 0 1rem;
  -moz-border-radius: 0 1rem 0 1rem;
  -ms-border-radius: 0 1rem 0 1rem;
  -o-border-radius: 0 1rem 0 1rem;
}

.add-address-button,
.order-button {
  background-color: #ff8d95;
  font-size: 1.2rem;
  color: white;
  border: none;
  border-radius: 0.5rem;
  width: 14rem;
  height: 2.4rem;
  text-align: center;
}

.add-address-row {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.unselected_address_container {
  margin:1rem 3rem;
  /* width: 100%; */
  border: 1px solid var(--brown);
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  padding: 1em;
  border-radius: 0 1rem 0 1rem;
  -webkit-border-radius: 0 1rem 0 1rem;
  -moz-border-radius: 0 1rem 0 1rem;
  -ms-border-radius: 0 1rem 0 1rem;
  -o-border-radius: 0 1rem 0 1rem;
}

.address_selection_page{
  width: 100%;
  overflow-x:none ;
}
