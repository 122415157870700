.menu1-header {
  padding-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu1-heading {
  color: #f7fbfb;
  font-size: 4.5rem;
}
.menu1-cat {
  background-color: rgba(0, 0, 0, 0.5490196078431373);
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 1rem;
}
.menu1-items1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.menu1-items2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
}
.menu1-cat-item1 {
  display: flex;
  flex-direction: row;
}
.menu1-cat-details {
  display: flex;
  flex-direction: column;
}
.menu1-cat-item2 {
  display: flex;
  flex-direction: row;
}
.menu1-cat-add {
  border: none;
  border-radius: 0.8rem;
  color: white;
  background-color: #ff8d95;
  padding: 5px;
  margin-top: 1rem;
}
.menu1-cat-price {
  margin-top: 1rem;
  color: white;
}
.menu1-cat-weight{
  margin-top: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 1rem;
}

.menu1-cat-name{
 color: white;
 margin-top: 1rem;
}