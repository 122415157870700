.orderdetail__container {
  background-image: url("../images/customo-bg.webp");
  min-height: 30vw;
}

.orderdetail__main_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.orderdetail__content__container {
  background-color: var(--backgroundgrey);
  padding: 1rem 2rem;
  width: 80%;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.orderdetail__divider_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.orderdetail__divider_container_title {
  display: flex;
  flex-direction: row;
  width: 100%;
}


.table-orders {
  width: 90%;
}

@media screen and (max-width:900px) {
  .table-orders {
    width: 90%;
  }
  .orderdetail__content__container {
    /* background-color: var(--backgroundgrey); */
    padding: 0.5rem;
    width: 100%;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
  .orderproduct__product_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.orderproduct__order_container {
  width: 100%;
}

.orderproduct__order_container_sub {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.orderdetail__divider_container_title_item,
.orderproduct__product_container {
  width: 40%;
  text-align: center;
}

.orderdetail__divider_container_title_quantity,
.orderproduct__quantity_container {
  width: 10%;
  text-align: center;
}

.orderdetail__divider_container_title_price,
.orderproduct__price_container {
  width: 10%;
  text-align: center;
}

.order_container_product_image {
  height: 8rem;
  width: 8rem;
}

.orderproduct__product_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.orderproduct_cancel_button{
    background-color: #ff8d95;
    font-size: 1.2rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    width: 6rem;
    height: 2.4rem;
    text-align: center;
}
.orderproduct__quantity_container {
}

.orderproduct__price_container {
}

.orderproduct__cancel_container {
}
