
.search__container{
    background-image: url('../images/customo-bg.webp');
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2em 0 4em 0;
}

.search__container_search_box{
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 2rem;
   width: min-content;
   border: 2px solid white;
   padding: 0.4rem 1rem;
   border-radius: 12px;
   -webkit-border-radius:12px;
   -moz-border-radius:12px;
   -ms-border-radius:12px;
   -o-border-radius:12px;
}

.search__container_search_box__input{
    text-decoration: none;
    border: 0;
    outline: none;
    background-color: transparent;
    color: white;
    font-weight: 600;
    font-size: larger;

}

.search__container_search_box__icon{
    text-decoration: none;
    border: 0;
    outline: none;
    background-color: transparent;
}
