.coupon__container {
  background-image: url("../images/Check.webp");
  min-height: 40vh;
}

.coupon__heading {
  color: white;
}

.coupon__body__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.coupon_container__outer_body {
  background-color: var(--backgroundgrey);
  transition: transform 100ms ease-in-out;
  height: auto;
  color: white;
  min-width: 30%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2em 1em;
  margin: 1em;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
}
.coupon_container__outer_body:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.coupon__image {
  height: 4rem;
  width: 4rem;
  border-radius: 0 1rem 0 1rem;
  -webkit-border-radius: 0 1rem 0 1rem;
  -moz-border-radius: 0 1rem 0 1rem;
  -ms-border-radius: 0 1rem 0 1rem;
  -o-border-radius: 0 1rem 0 1rem;
}

.coupon__detail_container {
  margin-left: 0.6em;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.coupon__detail_container_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.coupon__code {
  font-size: larger;
  font-weight: bold;
}

.coupon__description {
  font-size: medium;
  font-weight: 400;
}
