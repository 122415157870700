.coustomer-bill {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5490196078431373);
  padding-bottom: 2rem;
  border-radius: 2rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.add-address-page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../images/Check.webp");
}

.coustomer-first {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 4rem;
}

.coustomer-second {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 5rem;
  padding: 4rem;
}

.coustomer-heading {
  padding-top: 1rem;
  text-align: center;
  color: white;
}

.coustomer-add-btn {
  border: none;
  border-radius: 1.2rem;
  background-color: #ff8d95;
  padding: 1.2rem;
  width: 18rem;
  color: white;
}

.coustomer-add {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 766px) {
  .coustomer-add {
    flex-direction: column;
  }

  .coustomer-heading {
    margin-bottom: 1rem;
  }

  .coustomer-first {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .coustomer-second {
    padding: 0;
  }

  .coustomer-bill {
    padding: 2rem;
    margin-top: 1.5rem;
    border-radius: 1.2rem;
  }

  .coustomer-add-btn {
    margin-top: 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #ff8d95;
    width: 7rem;
    color: white;
  }
}
