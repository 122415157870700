.mobile__logo_container{
    width: 100%;
    margin-top: 2.8rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile__app_logo{
    height: 5rem;
} 

.mobile_navbar__divider{
    width: 42%;
    height: 0.1em;
    background-color: var(--brown);
}

.mobilenavbar__container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}