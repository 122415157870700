.cat-heading {
  text-align: center;
  color: #ff8d95;
  font-size: 2rem;
  padding-top: 15px;
}

.home-header {
  font-size: 1.2rem;
  height: 10vh;
  margin-top: 1rem;
}

.home-category {
  background-color: #87ddda;
}

.cat-name {
  font-size: 1.3rem;
  width: 18rem;
  margin-top: 1rem;
  color: black;
  text-align: center;
  margin-right: 4rem;
  font-weight: 500;
  background-color: var(--lightyellow);
  border-radius: 0.75rem;
}

.cat-full {
  margin: 1.5rem 2rem;
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.cat-img {
  width: 18rem;
  height: 18rem;
  border: 0.4rem solid var(--lightyellow);
}

/*
  ================= story section =====================
*/

.home_story__container {
  background-color: #87ddda;
  padding: 1em 0;
}

.home_story__title {
  color: white;
  text-align: center;
}

.home_story__content_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_story__content_body_container {
  color: white;
  text-align: justify;
  margin: 0 2em;
}

.home_story__content_image_right,
.home_story__content_image_left {
  height: 15em;
  width: 15em;
  object-fit: cover;
  margin: 0 0.3em;
}

@media screen and (max-width: 875px) {
  .home_story__content_container {
    flex-direction: column;
  }
  .home_story__content_image_right {
    display: none;
  }
}

.testimonial {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background-color:var(--lightpink) ;

  padding: 2rem 2rem;
}

.testHead {
  font-size: 2.5rem;
  color: var(--brown);
  text-align: center;

  font-weight: 500;
}

.testCardContainer {
  display: flex;

  gap: 2rem;

  width: 100%;
}

.testimonialCard__name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--brown);
  text-align: center;
  margin-bottom: 0;
  font-family: "Segoe UI";
}
.fulltest {
  padding: 1rem;
}
.swiper-slide {
  height: auto !important;
}

.testimonialCard {
  background: #ffffff;
  box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.24);
  border-radius: 12px;
  padding: 1rem 1.5rem;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-self: stretch;
}
.testimonialCard__content {
  display: flex;
  flex-direction: column;

  margin-top: 1rem;
}
.testimonialCard__text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  font-family: "Segoe UI";
  text-align: center;
  margin-bottom: 0;

  line-height: 18px;
  color: var(--brown);
}

.testimonialCard__image {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.testimonialCard__image img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 875px) {
  .home_story__content_container {
    flex-direction: column;
  }
  .home_story__content_image_right {
    display: none;
  }
  .cat-img {
    width: 100%;
  }
  .home_story__container {
    background-color: #87ddda;
    padding: 2em 0rem;
  }
  .testCardContainer {
    flex-direction: column;
  }
}

@media screen and (min-width: 1268px) {
  .testimonialCard__name {
    font-size: 18px;
  }

  .testimonialCard {
    padding: 1.5rem 2rem;
  }
  .testimonialCard__content {
    display: flex;
    flex-direction: column;

    margin-top: 1rem;
  }
  .testimonialCard__text {
    font-size: 16px;
  }
}
@media screen and (min-width: 1468px) {
  .testimonialCard__name {
    font-size: 22px;
  }

  .testimonialCard {
    padding: 1.5rem 2rem;
  }
  .testimonialCard__content {
    display: flex;
    flex-direction: column;

    margin-top: 1rem;
  }
  .testimonialCard__text {
    font-size: 18px;

    line-height: 22px;
  }
}
