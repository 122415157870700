.select_store__container {
  padding-top: 150px;
  background-image: url('../images/Check.webp');
}


.location-img {
  width: 17rem;
  height: 17rem;
  margin-left: 3.8rem;
  margin-top: 2rem;

  border-radius: 2rem;
}

.store-indi {
  width: 25rem;
  height: 32rem;
  background-color: var(--backgroundgrey);
  border-radius: 2rem;
  margin:0.2em ;
}

.stores {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.store-add {
  color: white;
  margin-left: 5.7rem;
  margin-right: 5rem;
  margin-top: 1rem;
}

.store-radio {
  margin-left: 4.5rem;
}

.store-select {
  font-size: 1.2rem;
  color: white;
}

.radio-btn {
  margin-right: 1rem;
  margin-top: 1rem;
  height: 1.4rem;
  width: 1.4rem;
 
}

.store-heading {
  text-align: center;
  color: white;
  margin-bottom: 1rem;
  font-size: 2.2rem;
}

.select_store_container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.select_store_button{
  text-decoration: none;
  border: none;
  align-items: center;
  background-color: var(--lightpink);
  color: white;
  font-size: xx-large;
  margin: 2em;
  padding: 0.2em 1em;
  border-radius: 2em;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  -ms-border-radius: 2em;
  -o-border-radius: 2em;
}